/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Container, Theme, Typography } from '@material-ui/core'
import LaunchIcon from '@material-ui/icons/Launch'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    vehicleBrandsContainer: {
      position: 'relative',
      padding: '0 50px 0px 50px'
    },
    logo: {
      margin: '5%',
      [theme.breakpoints.up('md')]: {
        margin: 0
      }
    },

    workingHeader: {
      textAlign: 'center',
      fontSize: '1.5rem',
    },
    workingHr: {
      border: `2px solid ${theme.palette.secondary.main}`,
      width: '10%',
      [theme.breakpoints.up('md')]: {
        width: '5%'
      }
    },
    levc: {
      width: '70%',
      marginLeft: '10%'
    },
    teslaText: {
      width: '80%',
      marginTop: '6%'
    },
    jaguar: {
      width: '70%',
      marginLeft: '15%',
      marginTop: '5%'
    },
    logoContainer: {
      padding: '0rem 2rem 0rem 2rem'
    },
    link: {
      color: theme.typography.h6.color,
      textDecoration: "none"
    }
  })
)

type VideoComponentProps = {
  title: string
  linkText: string | null
  linkHref: string | null
  videoString: any
  captions: any
  cover: any
}

export default function VideoComponent({ title, linkText, linkHref, videoString, captions, cover }: VideoComponentProps) {
  const classes = useStyles()

  return (
    <>
      <Typography className={classes.workingHeader} variant="h6">
        {title}
        <hr className={classes.workingHr} />
      </Typography>
      <Container className={classes.logoContainer}>
        {
          linkText && linkHref
            ?
            (
              <>
                <Typography style={{ textAlign: 'center' }} variant="h6" gutterBottom>
                  <a className={classes.link} href={linkHref ?? ""}>{linkText} <LaunchIcon /></a>
                </Typography>
              </>
            )
            :
            (
              <>
              </>
            )
        }
        <br />
        <div style={{ width: "100%", paddingTop: "56.25%", height: "0px", position: "relative", filter: 'drop-shadow(0px 0px 30px #a7a7a7)' }}>
          <video preload="metadata" poster={cover} style={{ height: '100%', maxWidth: '100%', position: 'absolute', top: '0', left: '0' }} controls>
            <source src={videoString} type="video/mp4" />
            <track kind="captions" srcLang="en" src={captions} />
          </video>
        </div>
        <br />
        <br />
        <br />
      </Container>

    </>
  )
}
