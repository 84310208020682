/* eslint-disable react/require-default-props */
/* eslint-disable react/destructuring-assignment */
import * as React from 'react'
import { Link } from 'gatsby'
import { makeStyles, Theme, createStyles, useMediaQuery, useTheme, Grid, Typography, Box, Button } from '@material-ui/core'
import { useEffect, useRef } from 'react'
import ArrowDropDownCircleOutlinedIcon from '@material-ui/icons/ArrowDropDownCircleOutlined'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heroImage: {
      height: 'auto',
      gridArea: '1/1',
      display: 'grid'
    },
    heroImageDesktop: {
      height: 'auto',
      width: '100vw',
      display: 'grid',
      gridArea: '1/1',
    },
    topHero: {
      width: '60vw',
      position: 'absolute',
      right: 0
    },
    heroText: {
      marginTop: '1rem',
      marginBottom: '0.5rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '2rem'
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: '3rem'
      },
      '@media (max-width: 375px)': {
        fontSize: '1rem',
        marginTop: '1.2rem'
      },
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center'
      }
    },
    heroSub: {
      [theme.breakpoints.up('md')]: {
        fontSize: '1.2rem'
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: '1.8rem'
      },
      '@media (max-width: 375px)': {
        fontSize: '0.8rem'
      }
    },
    heroBox: {
      position: 'relative',
      placeItems: 'flex-start',
      display: 'grid',
      gridArea: '1/1',
      margin: '0rem 2rem 0rem 2rem',
      [theme.breakpoints.up('sm')]: {
        padding: '0rem 0rem 0rem 2rem'
      },
      [theme.breakpoints.up('md')]: {
        padding: '0rem 0rem 0rem 3rem'
      }
    },
    heroButton: {
      borderRadius: '25px',
      fontFamily: 'Montserrat',
      fontSize: '1rem',
      marginTop: '1rem',
      textTransform: 'capitalize',
      [theme.breakpoints.up('md')]: {
        fontSize: '1.3rem'
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: '2rem'
      },
      '@media (max-width: 375px)': {
        fontSize: '0.8rem'
      },
      '& div': {
        textDecoration: 'none'
      }
    },
    hiddenLink: {
      textDecoration: 'none'
    },
    downArrow: {
      marginLeft: 'auto',
      marginRight: 'auto',
      cursor: 'pointer',
      fontSize: 50,
      [theme.breakpoints.down('sm')]: {
        visibility: 'hidden'
      },
      [theme.breakpoints.up('sm')]: {
        visibility: 'visible',        
        marginBottom: '0',
        paddingTop: '0'        
      },
      [theme.breakpoints.up('lg')]: {
        marginTop: '1rem',       
        marginBottom: '20%'      
      }
    }
  })
)

interface HeroProps {
  videoPosterMobile?: string
  videoPosterDesktop?: string
  videoSourceDesktop?: string
  videoSourceDesktopMp4?: string
  videoSourceMobile?: string
  videoSourceMobileMp4?: string
  mainText: string
  bodyText: any
  ctaLink?: string
  ctaText?: string
  extraText?: any
  inverse?: boolean
  IncluceDownArrow: boolean
  topHero?: boolean
}

function marginFunc(inverse: boolean | undefined, biggerThanMobile: boolean) {
  if (inverse && biggerThanMobile) {
    return { marginRight: '55%' }
  }
  if (biggerThanMobile) {
    return { marginLeft: '55%' }
  }
  return {}
}

export default function Hero(props: HeroProps) {
  const classes = useStyles()
  const theme = useTheme()
  const biggerThanMobile = useMediaQuery(theme.breakpoints.up('sm'))
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

  const videoRef = useRef(undefined)
  useEffect(() => {
    videoRef.current.defaultMuted = true
  })

  return (
    <div>
      <div style={{ display: 'grid', minHeight: props.topHero ? 'calc(60vw/1.6)' : '30vh' }}>
        {biggerThanMobile ? (
          <video ref={videoRef} playsInline poster={props.videoPosterDesktop} autoPlay muted loop className={classes.heroImageDesktop + " " + (props.topHero ? classes.topHero : "")}>
            <source src={props.videoSourceDesktop} type="video/webm" />
            <source src={props.videoSourceDesktopMp4} type="video/mp4" />
          </video>
        ) : (
          <>
            <video style={{paddingTop: '30px'}} ref={videoRef} playsInline poster={props.videoPosterMobile} autoPlay muted loop className={classes.heroImageDesktop}>
              <source src={props.videoSourceMobileMp4} type="video/mp4" />
              <source src={props.videoSourceMobile} type="video/webm" />
            </video>
          </>
        )}
        <Box className={classes.heroBox} style={marginFunc(props.inverse, biggerThanMobile)}>
          <div>
            {biggerThanMobile ? <div style={{ height: '10vh' }} /> : <div style={{ height: '1vh' }} />}
            {isDesktop && <div style={{ height: '5vh' }} />}
            <Typography className={classes.heroText} variant="h6">
              {props.mainText}
            </Typography>
            <Typography variant="body1" className={classes.heroSub}>
              {props.bodyText}
            </Typography>
            <Grid container justifyContent={biggerThanMobile ? 'flex-start' : 'center'}>
              {props.ctaLink && props.ctaText && (
                <Link className={classes.hiddenLink} to={props.ctaLink}>
                  <Button color="secondary" className={classes.heroButton} variant="contained">
                    {props.ctaText}
                  </Button>
                </Link>
              )}
            </Grid>
            {props.extraText && (
              <Typography variant="body1" className={classes.heroSub}>
                {props.extraText}
              </Typography>
            )}
          </div>
          {props.IncluceDownArrow ? (
            <ArrowDropDownCircleOutlinedIcon
              onClick={() => window.scrollBy({ top: window.visualViewport.height ?? 1000, behavior: 'smooth' })}
              color="primary"
              className={classes.downArrow}
            ></ArrowDropDownCircleOutlinedIcon>
          ) : (
            <div></div>
          )}
        </Box>
      </div>
    </div>
  )
}
