import * as React from 'react'
import { Container, createStyles, makeStyles, Typography, Grid, Theme, Button } from '@material-ui/core'
import { graphql, Link } from 'gatsby'
import LaunchIcon from '@material-ui/icons/Launch'
import IndexLayout from '../templates/IndexLayout'
import ProfileCard from '../components/ProfileCard'
import Hero from '../components/Hero'

import sustainabilityVideoDesktop from '../videos/sustainability-desktop-cropped-hd.webm'
import sustainabilityVideoMobile from '../videos/sustainability-mobile-cropped-hd.webm'
import sustainabilityPosterDesktop from '../videos/sustainability-desktop-thumbnail-hd.webp'
import sustainabilityPosterMobile from '../videos/sustainability-mobile-thumbnail-hd.webp'

import telematicsVideoDesktop from '../videos/telematics-desktop-cropped-hd.webm'
import telematicsVideoMobile from '../videos/telematics-mobile-cropped-hd.webm'
import telematicsPosterDesktop from '../videos/telematics-desktop-thumbnail-hd.webp'
import telematicsPosterMobile from '../videos/telematics-mobile-thumbnail-hd.webp'

import culturePosterDesktop from '../videos/culture-desktop-thumbnail-hd.webp'
import culturePosterMobile from '../videos/culture-mobile-thumbnail-hd.webp'
import VideoComponent from '../components/VideoComponent'


import captions from 'file-loader!../videos/day_in_the_life.vtt'
import msCover from '../videos/zeti-cover.png'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      marginTop: '1rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '2rem'
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: '3rem'
      },
      '@media (max-width: 375px)': {
        fontSize: '1rem',
        marginTop: '1.2rem'
      },
      textAlign: 'center'
    },
    subheader: {
      [theme.breakpoints.up('md')]: {
        fontSize: '2rem'
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: '3rem'
      },
      '@media (max-width: 375px)': {
        fontSize: '0.9rem',
        marginBottom: '0.6rem'
      },
      textAlign: 'center'
    },
    bodyText: {
      [theme.breakpoints.up('md')]: {
        fontSize: '1.2rem'
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: '1.8rem'
      },
      '@media (max-width: 375px)': {
        fontSize: '0.8rem'
      },
      textAlign: 'center'
    },
    mdxSection: {
      textAlign: 'center'
    },
    blogListingSection: {
      marginTop: theme.spacing(1)
    },
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    readMoreButton: {
      textDecoration: 'none'
    },
    media: {
      height: 0,
      paddingTop: '56.25%' // 16:9
    },
    jobLocation: {
      color: theme.palette.primary.main
    },
    topButton: {
      textAlign: 'center',
      borderRadius: '25px',
      fontFamily: 'Montserrat',
      fontSize: '1rem',
      marginTop: '1rem',
      textTransform: 'capitalize',
      [theme.breakpoints.up('md')]: {
        fontSize: '1.3rem'
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: '2rem'
      },
      '@media (max-width: 375px)': {
        fontSize: '0.8rem'
      },
      '& div': {
        textDecoration: 'none'
      }
    }
  })
)

export default function Careers({ data }: any) {
  const { edges } = data.allMarkdownRemark
  const filteredEdges = edges.filter((edge: any) => edge.node.frontmatter?.title !== "placeholder")
  const classes = useStyles()
  return (
    <IndexLayout title="Careers @ Zeti">
      <div style={{ height: '7vh' }} />
      <Container maxWidth="md" style={{textAlign: 'center', marginBottom: '100px'}}>
        <Typography variant="h6" className={classes.header}>
          Why Zeti?
        </Typography>
        <Typography variant="h6" className={classes.subheader}>
          Help us tackle the climate crisis.
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
        Zeti is revolutionizing the financing of clean transport, in order to support both businesses and society’s goals. In order to do this, we need brilliant people with a passion for sustainability who want to spend their days doing something that really matters whilst developing their talent and sharing the experience with other like-minded people!
        </Typography>
        <Button color="secondary" variant="contained" className={classes.topButton} href="#jobs">View All Jobs</Button>
      </Container>
      <VideoComponent title={"A Day in the Life"} videoString='/videos/day_in_the_life.mp4' captions={captions} cover={msCover} linkText="" linkHref="" />
      <Hero
        inverse
        videoSourceDesktop={telematicsVideoDesktop}
        videoSourceMobile={telematicsVideoMobile}
        videoPosterDesktop={telematicsPosterDesktop}
        videoPosterMobile={telematicsPosterMobile}
        mainText="Work with our cutting-edge technology"
        bodyText={
          <>
            We are a fast-moving, agile and open-minded technology led business, that deploys new features to our customers every week. Our patent pending ZERO platform enables an award-winning pay-per-mile model in an unprecedented format and allows financiers to understand and interact with their investments in a more tangible way, whilst simultaneously allowing fleet operators to transition to clean transport solutions without a cash flow burden.
          </>
        }
        ctaLink="#jobs"
        ctaText="Apply Now"
      />
      <Hero
        videoSourceDesktop={sustainabilityVideoDesktop}
        videoSourceMobile={sustainabilityVideoMobile}
        videoPosterDesktop={sustainabilityPosterDesktop}
        videoPosterMobile={sustainabilityPosterMobile}
        mainText="Sustainability"
        bodyText={
          <>
            We put sustainability at the heart of everything we do. As the climate crisis comes to a head, incoming regulations and societal pressure have forced us all to think differently about transport as we know it. Here at Zeti, we want to be a part of the global green revolution. We created an innovative new platform to accelerate the adoption of zero and ultra-low emission technology.
          </>
        }
        ctaLink="#jobs"
        ctaText="Apply Now"
      />
      <Hero
        inverse
        videoPosterDesktop={culturePosterDesktop}
        videoPosterMobile={culturePosterMobile}
        mainText="An exciting dynamic culture"
        bodyText={
          <>
            Our people are driven by a desire to work with the transport, technology and finance industries to enable them to move forward with speed in order to play their part in solving the climate crisis. We believe that people want to do their best work, and we give them the space to do so, along with the opportunity to work flexibly, autonomously and authentically.{' '}
          </>
        }
        ctaLink="#jobs"
        ctaText="Apply Now"
      />
      <Container maxWidth="md">
        <Grid container justifyContent="center" spacing={3} id="jobs">
          <Grid item sm={12}>
            <Typography className={classes.header} variant="h6">
              Jobs
            </Typography>
            <Grid className={classes.blogListingSection} container direction="row" justify="center" alignItems="stretch" spacing={3}>
              {filteredEdges.length > 0 ? filteredEdges.map((edge: any) => {
                return (
                  <ProfileCard
                    header={edge.node.frontmatter?.title}
                    subheader={edge.node.frontmatter?.location}
                    mainText={edge.node.excerpt.replace('About the Role','')}
                    ctaLink={edge.node.fields.slug}
                    ctaIcon={LaunchIcon}
                  />
                )
              })
                :
                <Typography variant="subtitle1">Sorry we don't have any job listings at the moment, but we are always on the lookout for fantastic talent. Please get in touch <a href="mailto:careers@zeti.co.uk">here</a></Typography>
              }
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </IndexLayout >
  )
}

export const jobsQuery = graphql`
  {
    allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "job" } } }, sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            location
          }
        }
      }
    }
  }
`
